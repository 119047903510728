.toggle__dot {
  top: 0.1rem !important;
  left: 0.2rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  top: 0.1rem;
  left: -0.2rem;
}

.toggle__line {
  background-color: #dfdfe1;
}

input:checked ~ .toggle__line {
  background: #4290f3;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #78ca95 !important;
  text-shadow: 0 0 0.25px;
  font-style: normal;
  font-weight: normal;
  /* font-size: 12px; */
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-btn:hover {
  color: #78ca95 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #78ca95 !important;
  pointer-events: none;
}
