.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-pic {
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.information-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.name {
  color: #1d1a1a;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
  margin: 0;
}

.subtitle-container {
  display: flex;
  align-items: center;
  gap: 7px;
}

.subtitle {
  color: #666;
  font-size: 14px;
  font-weight: 450;
  line-height: 100%; /* 14px */
  letter-spacing: -0.14px;
  margin: 0;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  padding: 37px 0;
}

.button-icon {
  width: 29px;
  height: 29px;
}

.button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #000;

  font-size: 16px;
  font-weight: 450;
  line-height: 100%; /* 16px */
  letter-spacing: -0.16px;

  padding: 10px 15px;

  border-radius: 6px;
  border: 1px solid #bababa;
  width: 100%;
}

.button:hover {
  color: #000;
  background-color: #f1f1f1;
}

.button:focus {
  color: #000;
  background-color: #ecf2fd;
}

.close-button {
  color: #999999;
  width: 34px;
  height: 34px;
}

.close-button:hover {
  color: #666666;
}

.close-button:focus {
  color: #333333;
}
