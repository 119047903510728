.radio-pill-input .radio-pill-input__input {
    appearance: none;

    border-radius: 50%;
    width: 20px;
    height: 20px;

    background-color: #fff;
    border: 1.5px solid var(--color-blue);
    transition: 0.1s border-width linear;

    &:checked {
        border-width: 5px;
    }
}

/* .radio-pill-input .radio-pill-input__input:checked {
    border-width: 5px;
} */