.tree {
  position: relative;
  padding-left: 0;
  margin-left: 0;
}

.tree li {
  position: relative;
  z-index: 9;
}

.tree li:not(:last-child)::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 20px;
  bottom: 0;
  left: 24px;
  transform: translateY(50%);
  background-color: #ccc;
}

.tree li div {
  width: 48px;
  height: 48px;
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid #fff;
}
