.form-select-wrapper {
  .ant-select-selector {
    background: var(--color-gray-lightest) !important;
    border: 1px solid #DFDFE1 !important;
    outline: none !important;
    border-radius: 4px;
  }

  .ant-select-selection-placeholder {
    color: #8B8896;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
}

.form-select__menu {
  /* ant design select menu option wrapper */
  div:nth-child(2) > div > div {
    flex-wrap: wrap;
    flex-direction: row !important;
  }
}

.form-select__option {
  width: 50%;
  background: none !important;
  padding: 12px 20px !important;
  
  &:before {
    content: '';
    position: relative;
    margin: auto 10px;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: var(--color-gray-lightest);
    border: 1px solid #DFDFE1;
  }

  &.ant-select-item-option-selected {
    &:before {
      background-color: var(--color-blue);
      border: 1px solid rgba(66, 144, 243, 0.1);
    }
  }

  & .ant-select-item-option-state {
    display: none;
  }

  & .ant-select-item-option-content {
    color: #8B8896;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400 !important;
  }
}

.course-switcher {
  border: 1px solid #C5CEE0;
  border-radius: 71px !important;

  & .react-switch-bg {
    background: rgba(143, 155, 179, 0.16) !important;
  }
}

.auth-slider {
  .slick-dots {

    @media (min-width: 768px) {
      bottom: -65px;
    }

    li {
      margin: 0 2px;

      & button::before,
      &.slick-active button::before {
        opacity: 1;
      }

      & button::before {
        font-size: 10px;
        color: rgba(66, 144, 243, 0.3);
      }

      &.slick-active button::before {
        color: var(--color-blue);
      }
    }
  }
}

.referral-form-select {
  .ant-select-selector {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}