.form-input {
    border: 1px solid var(--color-gray-lighter-soft);
    padding: 11px 15px;
    width: 100%;
    display: block;

    &,
    &:focus,
    &:hover {
        color: var(--color-gray-light);
        background-color: #fff;
        outline: 0;
    }
}

[disabled] {
    opacity: 0.5;
}
