.study-concept__item__circle {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #DBEAFD;
  background-color: #fff;
  display: inline-block;
  margin-right: 13px;
  vertical-align: middle;

  .study-concept__item--active & {
    width: 16px;
    height: 16px;
    position: relative;
    border-color: var(--color-blue);

    &::before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      border-radius: 50%;
      position: absolute;
      background-color: var(--color-blue);
    }
  }  
}