.card-hover-override:hover {
  box-shadow: 0px 12px 24px rgba(66, 144, 243, 0.12);
}

.card-hover-override:hover .card-hover-newpage {
  transition: opacity 0.5s;
  opacity: 1;
}

.card-hover-newpage {
  opacity: 0.5;
}

.exam-prep-modal .ant-modal-content {
  border-radius: 15px;
}

#examprep-watch-solution:hover {
  background-color: #EBF3FD !important;
  color: black;
}