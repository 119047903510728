.app-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  width: var(--sidebar-width);
}

.app-sidebar__item {
  display: flex;
  padding: 6px 25px;
  align-items: center;
  text-decoration: none !important;
  border-left: 2px solid transparent;

  .app-sidebar__item__title {
    color: var(--color-text-70);
    white-space: nowrap;
  }
}

.app-sidebar__item__icon {
  min-width: 29px;
  width: 29px;
  height: 29px;
  min-height: 29px;
  border-radius: 15px;
  display: flex;
  color: rgba(64, 129, 235, 0.1);
  background: rgba(64, 129, 235, 0.1);
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    max-width: 18px;
  }
}

.app-sidebar__item--active {
  opacity: 1;
  background-color: #ecf2fd;
  border-color: var(--color-blue);

  .app-sidebar__item__title {
    color: var(--color-blue);
  }
}

.app-sidebar__item:hover {
  opacity: 1;
  background-color: #ecf2fd;

  .app-sidebar__item__title {
    color: #80a1df;
  }
}

.app-right-sidebar {
  margin-top: calc(var(--main-content-spacing) * -1);
  margin-right: calc(var(--main-content-spacing) * -1);
  padding-top: var(--main-content-spacing);
  padding-right: var(--main-content-spacing);
  min-height: calc(100vh - var(--header-height-desktop));
  padding-left: 16px;
  padding-right: 16px;
  z-index: 35;
}

.app-right-sidebar__icon {
  width: 52px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-right-sidebar__item {
  background-color: #fff;
  padding: 10px 0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.app-right-sidebar__item__divider {
  border-top: 1px solid #dfdfe1;
  width: 50%;
  margin: 8px 0;
}

.app-right-sidebar__item__button {
  padding: 2px 5px;
  border-radius: 10px;

  &:hover {
    background-color: #ecf2fd;
  }
}
