@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 300;
  src: local('Circular Std Light'), url('../assets/fonts/CircularStd-Light.woff') format('woff')
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 400;
  src: local('Circular Std Book Regular'),url('../assets/fonts/CircularStd-Book.woff') format('woff')
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 500;
  src: local('Circular Std Medium Regular'),url('../assets/fonts/CircularStd-Medium.woff') format('woff')
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 700;
  src: local('Circular Std Bold Regular'),url('../assets/fonts/CircularStd-Bold.woff') format('woff')
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 900;
  src: local('Circular Std Black Regular'),url('../assets/fonts/CircularStd-Black.woff') format('woff')
}

