.modal-email-preferences {
  padding: 16px;

  .title {
    font-size: 34px;
    color: #121212;
    font-weight: 500;
  }

  .sub-title {
    color: #666666;
    font-size: 16px;
    font-weight: 300;
  }

  .checkbox-section {
    & > div {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .section-title {
      color: #121212;
      font-size: 20px;
      font-weight: 500;
    }

    .section-description {
      color: #666666;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;

    }
  }

  .divider {
    background: #EAEAEA;
    height: 1px;
    margin: 26px 0 18px;
  }
}