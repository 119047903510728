.npp-modal .ant-input-affix-wrapper {
    padding: 0.5rem !important;
    border: 1px solid var(--color-gray-focus) !important;
    border-bottom-width: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--color-gray-lightest) !important;
    color: var(--color-text-70) !important;
    font-weight: 400 !important;
}

.npp-modal .ant-input-affix-wrapper:hover {
    border-color: var(--color-gray-focus) !important;
}

.npp-modal .ant-input-affix-wrapper:focus, .npp-modal .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.npp-modal .ant-input-affix-wrapper .ant-input-prefix {
    margin-right: 1.5rem;
}

.npp-modal .ant-input-affix-wrapper .ant-input {
    background-color: var(--color-gray-lightest) !important;
    color: var(--color-text-70) !important;
    font-weight: 400 !important;
}

.npp-modal .ant-modal .ant-modal-content .ant-modal-header {
    border-width: 0;
}

.npp-modal .ant-modal-body {
    padding-top: 0;
}

.npp-modal .ant-modal-content {
    border-radius: 1.25rem !important;
    overflow: hidden !important;
}

.tag-list .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
}

.tag-list .ant-select, .tag-list .ant-select-selector {
    border-top-right-radius: inherit !important;
    border-top-left-radius: inherit !important;
}

.tag-list .ant-select-selector {
    border-width: 0 !important;
    background-color: var(--color-gray-lightest) !important;
    border-color: transparent !important;
}

.tag-list .ant-select-dropdown {
    min-width: auto !important;
    left: 3rem !important;
    right: 3rem !important;
    width: auto !important;
}