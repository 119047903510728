.knowledge-test-carousel {
  .slick-dots {
    position: relative;
    bottom: 0;
    left: 8px;
    width: auto;
    display: inline-block !important;

    li {
      margin: 0 2px;
    
      button:before {
        font-size: 10px;
        color: #5A7184;
        opacity: 0.1;
      }
  
      &.slick-active button::before {
        font-size: 12px;
        opacity: 1;
        color: #4081EB;
      }
    }
  }
}

.knowledge-test-carousel__arrow {
  color: #ddd;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;

  &:not([disabled]) {
    color: #4081EB;
  }
}


.knowledge-test-carousel__arrow--prev {
  right: 55px;
}

.knowledge-test-carousel__arrow--next {
  svg {
    transform: rotate(180deg);
  }
}