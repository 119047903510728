.react-responsive-modal-closeButton {
  position: absolute;
  top: 5px;
  right: 6px;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  color: white;
  background: white;
  height: 27px;
  font-size: 10px;
}
