.app-header-right {
  width: var(--sidebar-width);
}

.user-dropdown__nav {
  top: 40px;
  min-width: 224px;
  border-width: 1px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
  
  & .user-dropdown__nav__item{
    position: relative;

    &:not(:last-of-type) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--color-gray-thin);
        height: 1px;
        left: 1.5rem;
      }
    }
  }
}
