.practice-problem-option {
  input {
    z-index: 1;

    &:checked + .practice-problem-option__content {
      background-color: #EEEEF0;

      .practice-problem-option__circle {
        span {
          display: inline-block;
        }
      }
    }
  }
}

.practice-problem-option__circle {
  margin-right: 14px;
  display: inline-block;
}

.practice-problem-option__text {
  max-height: 150px;
  overflow: scroll;
}

.practice-problem-option__content {
  padding: 10px 4px 11px 7px;
  border: 1px solid transparent;

  .practice-problem-option--valid & {
    background-color: #FEF8EF !important;
    border: 1px solid #EAEAEA;
  }

  .practice-problem-option--correct-choice & {
    background-color: #F1FAF4 !important;
    border: 1px solid #78CA95;
  }

  .practice-problem-option--wrong-choice & {
    background-color: #F8EAEB !important;
    border: 1px solid #EAEAEA;
  }
}

.practice-problem__record__circe {
  animation-duration: 1s;
  animation-name: edlyft-scale;
  animation-iteration-count: infinite;
}

@keyframes edlyft-scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2.5);
  }
}
