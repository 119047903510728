.player-wrapper {
  background: var(--color-gray-lightest);
}

.player-wrapper::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect ratio */
}

.player-wrapper::after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}