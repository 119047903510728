.timestamps-list {
    border-top: 1px solid rgba(71,67,84, 0.1);
    border-bottom: 1px solid rgba(71,67,84, 0.1);
    padding-top: 5px;
    margin-top: 15px;
    padding-bottom: 15px;
}

.timestamps-list li {
    margin-top: 20px;
}

.timestamps-list li {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;
}

.timestamps-list li span:first-child {
    margin-right: 20px;
    display: inline-block;
    color: #666666;
}

.timestamps-list li:hover  .controls {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
}

.timestamps-list input {
    width: 50px;
    background-color: #fff;
    border-radius: 2px;
}

.controls {
    background-color: rgba(235, 243, 253, 0.9);
    box-shadow: 2px 2px 2px rgba(235, 243, 253, 0.9);
    width: auto;
    display: none;
    margin-left: 10px;
    justify-content: space-between;
    padding: 2px;
}

.controls button {
    padding: 0px 2px;
    margin: 2px;
    font-size: 12px;
}

.controls button:focus {
    outline: none;
}

.timestamps-inputs form {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.timestamps-inputs input {
    padding: 2px;
    text-align: center;
    background-color: #fff;
}

.timestamps-inputs input[name="timestamp"] {
    width: 30%;
    margin-right: 10px;
}

.timestamps-inputs input[name="sentence"] {
    width: 60%;
}

.timestamps-inputs input:valid {
    background-color: #fff;
}

.timestamps-inputs input:invalid {
    background-color: #fff;
    box-shadow: 0 0 5px 1px red;
}

.timestamps-inputs button, .timestamps-inputs button:focus {
    outline: none;
    margin-left: 10px;
}

.timestamps-pane {
    background-color: #ebf3fd;
    border-width: 1px;
    margin-bottom: 50px;
}

.timestamps-pane-header {
    color: #4290F3;
    font-size: 1.3em;
}

.timestamps-pane-footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.timestamps-pane-footer form {
    width: 100%;
    display: flex;
}

.add-new-timestamps-btn {
    width: 100%;
    height: 40px;
    background: rgba(66, 144, 243, 0.1);
    border: 1.2px solid #4290F3;
    border-radius: 5px;
    color: #4290F3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timestamps-empty {
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 20px 0;
}

.timestamps-confirm-button {
    margin-top: 20px;
    color: #fff;
    font-size: 0.9em;
    background-color: #4290F3;
    padding: 15px 0;
    border-radius: 4px;
}
